// Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import 'bootstrap/scss/functions';

// Include any default variable overrides here

// Include remainder of required Bootstrap stylesheets
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

// Include Bootstrap components that must be global
//@import 'bootstrap/scss/root';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/modal';

.shipearly-modal {
  // Include Bootstrap components that can be wrapped
  @import 'bootstrap/scss/reboot';
  @import 'bootstrap/scss/buttons';
  //@import 'bootstrap/scss/close';
  @import 'bootstrap/scss/spinners';
  @import 'bootstrap/scss/utilities/screenreaders';

  .modal-content {
    background: none;
    border: none;
  }

  .modal-header {
    border: none;
    padding: 0;
  }

  .modal-title {
    @extend .sr-only;
  }

  button.close {
    @extend .btn;
    @extend .btn-secondary;
    @include button-size($btn-padding-y, $btn-padding-x, 1.5rem, 1, 50%);
    z-index: 1;
  }

  .bootbox-body {
    background-color: #fff;
  }

  .bootbox-body {
    height: 90vh;
  }

  @include media-breakpoint-up(sm) {
    .modal-dialog {
      max-width: 90vw;
    }

    .bootbox-body {
      height: 80vh;
    }
  }

  .loading-dialog {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
